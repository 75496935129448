'use client';

import React, { useEffect, useState } from 'react';
import { Link } from 'components/link';
import clsx from 'clsx';
import { HeartFilledIcon, HearthIcon } from 'components/icon';
import Notification from 'components/notifications';
import { toast } from 'react-toastify';
import { useTranslation } from 'app/i18n/client';

import { addWishlist } from 'lib/nahdi-middleware/account/wishlist/add-customer-wishlist';
import { deleteWishlist } from 'lib/nahdi-middleware/account/wishlist/delete-customer-wishlist';
import { setAddWishlist, setDeleteWishlist } from 'redux/features/wishlist';
import { AlgoliaProduct } from 'types/product';
import { ProductData, ProductItemType } from 'types/cms/product-carousel';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { redirectToMyCartLoginHref } from 'utils/route-urls';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { gtmSetWishList } from 'lib/gtm';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

function FavoriteButton({
  productId,
  product,
  onAddToWishlist,
  onRemoveFromWishlist,
  onBeforeRemoveFromWishlist,
  onBeforeAddToWishlist,
  isCartItem,
  customClassName = '',
  heartIconClassName = '',
  heartIconParentClassName = '',
  language,
  region,
}: {
  productId: string;
  product: ProductItemType | AlgoliaProduct | ProductData | undefined;
  onAddToWishlist?: () => void | Promise<void>;
  onRemoveFromWishlist?: () => void;
  onBeforeAddToWishlist?: () => void | Promise<void>;
  onBeforeRemoveFromWishlist?: () => void | Promise<void>;
  isCartItem?: boolean;
  customClassName?: string;
  heartIconClassName?: string;
  heartIconParentClassName?: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const dispatch = useAppDispatch();
  const locale = getLocalePathFromLanguageRegion(language, region);
  const { t } = useTranslation(locale, 'cart');
  const { ts: ts2 } = useTranslation(locale, 'query-notifications');

  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );

  const wishlistInfo = useAppSelector((state) => state.wishlistReducer.data);

  const [isFavoriteProduct, setIsFavoriteProduct] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (wishlistInfo?.items && loading) {
      const isFavorite = wishlistInfo.items?.some(
        (item: { product: { sku: string } }) => item.product?.sku === productId
      );
      setIsFavoriteProduct(isFavorite);
    }
  }, [wishlistInfo.items, productId, loading]);

  const addToWishlist = async () => {
    setDisabledButton(true);
    onBeforeAddToWishlist && onBeforeAddToWishlist();
    try {
      const response = await addWishlist({
        body: [
          {
            sku: productId,
            quantity: 1,
          },
        ],
        wishlistId: wishlistInfo?.id || '',
        language,
        region,
      });
      gtmSetWishList(
        productId,
        false,
        language,
        region,
        product && 'attributes' in product
          ? product?.attributes?.fulfilment_source || ''
          : ''
      );
      const updatedWishlistInfo = {
        ...wishlistInfo,
        items: [
          ...(wishlistInfo?.items || []),
          {
            id: response?.id,
            product: {
              sku: productId,
              data: product,
            },
          },
        ],
      };

      if (response?.id) {
        onAddToWishlist && onAddToWishlist();
        toast(
          <Notification
            componentProps={{
              closeIcon: true,
              text: t('favorite_added'),
            }}
          />,
          {
            autoClose: 3000,
            hideProgressBar: true,
            theme: 'success',
            position: 'top-center',
          }
        );
        dispatch(setAddWishlist(updatedWishlistInfo));
        setLoading(true);
        setIsFavoriteProduct(true);
        setDisabledButton(false);
      } else {
        throw new Error(response?.error || 'problem occurred');
      }
    } catch (error) {
      setIsFavoriteProduct(false);

      toast(
        <Notification
          componentProps={{
            closeIcon: true,
            text: ts2(
              (typeof error === 'string' && error) ||
                (error instanceof Error && error.message) ||
                'problem occurred'
            ),
          }}
        />,
        {
          autoClose: 3000,
          hideProgressBar: true,
          theme: 'error',
          position: 'top-center',
        }
      );
      setDisabledButton(false);
    }
  };

  const deleteToWishlist = async () => {
    if (!wishlistInfo?.items) return;

    const matchingItem = wishlistInfo.items.find(
      (item: { product: { sku: string } }) => item.product?.sku === productId
    );

    if (!matchingItem) return;

    setDisabledButton(true);

    try {
      const response = await deleteWishlist({
        body: Number(matchingItem.id),
        wishlistId: wishlistInfo?.id || '',
        language,
        region,
      });

      if (response?.id) {
        gtmSetWishList(
          matchingItem?.product?.sku.toString(),
          true,
          language,
          region,
          product && 'attributes' in product
            ? product?.attributes?.fulfilment_source || ''
            : ''
        );
        onRemoveFromWishlist && onRemoveFromWishlist();
        toast(
          <Notification
            componentProps={{
              closeIcon: true,
              text: t('favorite_deleted'),
            }}
          />,
          {
            autoClose: 3000,
            hideProgressBar: true,
            theme: 'success',
            position: 'top-center',
          }
        );

        const updatedWishlistInfo = {
          ...wishlistInfo,
          items: wishlistInfo.items.filter(
            (item: { product: { sku: string } }) =>
              item.product?.sku !== productId
          ),
        };

        dispatch(setDeleteWishlist(updatedWishlistInfo));
        setIsFavoriteProduct(false);
        setDisabledButton(false);
      } else {
        throw new Error(response?.error || 'problem occurred');
      }
    } catch (error) {
      toast(
        <Notification
          componentProps={{
            closeIcon: true,
            text: ts2(
              (typeof error === 'string' && error) ||
                (error instanceof Error && error.message) ||
                'problem occurred'
            ),
          }}
        />,
        {
          autoClose: 3000,
          hideProgressBar: true,
          theme: 'error',
          position: 'top-center',
        }
      );
      setDisabledButton(false);
    }
  };

  return (
    <div
      className={clsx(
        `${!isCartItem ? 'absolute end-0 top-0 h-8 w-8' : ''}`,
        `${
          !isCartItem
            ? 'z-10 flex items-center justify-center rounded-full border border-white bg-white shadow-standart transition-all'
            : ''
        }`,
        `${disabledButton ? 'cursor-not-allowed' : ''}`,
        customClassName
      )}
    >
      {miniProfile ? (
        !isFavoriteProduct ? (
          <button
            className={clsx(
              `${
                isCartItem
                  ? 'flex items-center space-x-2 bg-transparent px-2.5 lg:px-3 rtl:space-x-reverse'
                  : ''
              }`
            )}
            onClick={() => {
              addToWishlist();
            }}
            disabled={disabledButton}
          >
            <div
              className={clsx([
                `rounded-full bg-white ${heartIconParentClassName}`,
              ])}
            >
              <HearthIcon />
            </div>
            {isCartItem && (
              <span className='text-custom-sm font-semibold text-gray-dark'>
                {t('move_to_wishlist')}
              </span>
            )}
          </button>
        ) : (
          <button
            className={clsx(
              `${
                isCartItem
                  ? 'flex items-center space-x-2 bg-transparent px-2.5 lg:px-3 rtl:space-x-reverse'
                  : ''
              }`
            )}
            onClick={() => {
              onBeforeRemoveFromWishlist && onBeforeRemoveFromWishlist();
              !isCartItem && deleteToWishlist();
            }}
            disabled={disabledButton}
          >
            <div
              className={clsx([
                `rounded-full bg-white ${heartIconParentClassName}`,
              ])}
            >
              <HeartFilledIcon
                className={`w-5 fill-blue text-blue ${heartIconClassName}`}
              />
            </div>
            {isCartItem && (
              <span className='text-custom-sm font-semibold text-gray-dark'>
                {t('move_to_wishlist')}
              </span>
            )}
          </button>
        )
      ) : (
        <Link
          className={clsx(
            `${
              isCartItem
                ? 'flex items-center space-x-2 px-2.5 lg:px-3 rtl:space-x-reverse'
                : ''
            }`
          )}
          href={isCartItem ? redirectToMyCartLoginHref : 'account'}
        >
          <div
            className={clsx([
              `rounded-full bg-white ${heartIconParentClassName}`,
            ])}
          >
            <HearthIcon />
          </div>
          {isCartItem && (
            <span className='text-custom-sm font-semibold text-gray-dark'>
              {t('move_to_wishlist')}
            </span>
          )}
        </Link>
      )}
    </div>
  );
}

export default FavoriteButton;
